<template>
    <div class="watchers-list">
        <div class="tag" v-for="user in watchingUsers">
            <span class="text">{{ user.name }}</span>
            <span class="action" @click="onRemoveClick(user)">✗</span>
        </div>
        <div v-if="showAddButton" class="tag hover" @click="onAddClick">
            <span class="text pointer">Add...</span>
        </div>
        <div v-if="adding" class="search">
            <input type="text" ref="searchInput" v-model="searchText" @blur="onSearchBlur"/>
            <div class="dropdown">
                <div v-for="user in searchUsers()" @click="onSearchResultClick(user)" class="search-result">
                    {{ user.name }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    props: ['watcherApi'],
    data: function () {
      return {
        adding: false,
        allUsers: [],
        watchingUsers: [],
        searchText: ''
      }
    },
    computed: {
      availableUsers: function () {
        return this.allUsers.filter((function (user) {
          var watchingUserIds = this.watchingUsers.map(function (u) {
            return u.id
          });
          return watchingUserIds.indexOf(user.id) === -1;
        }).bind(this));
      },
      showAddButton: function () {
        return !this.adding && this.availableUsers.length !== 0;
      }
    },
    methods: {
      onAddClick: function () {
        this.adding = true;
        this.$nextTick(function () {
          this.$refs.searchInput.focus();
        });
      },
      onRemoveClick: function (user) {
        this.watchingUsers = this.watchingUsers.filter(function (watchingUser) {
          return watchingUser !== user;
        });
        this.watcherApi.delete(user);
      },
      searchUsers: function () {
        var searchResult = this.availableUsers.filter((function (user) {
          var normUser = user.email.toLocaleLowerCase();
          var normSearch = this.searchText.toLocaleLowerCase();
          return normUser.indexOf(normSearch) !== -1
        }).bind(this));
        return searchResult;
      },
      onSearchBlur: function () {
        setTimeout((function () {
          this.adding = false;
        }).bind(this), 250);
      },
      onSearchResultClick: function (user) {
        this.adding = false;
        this.searchText = '';
        this.watchingUsers.push(user);
        this.watcherApi.add(user);
      }
    },
    created: function () {
      this.watcherApi.load((function (all, watching) {
        this.watchingUsers = watching;
        this.allUsers = all;
      }).bind(this));
    },
  }
</script>
