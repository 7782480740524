<template>
    <span :class="['rating', { editable: editable }]" @mouseleave="onMouseOut">
    <span
            v-for="n in 5"
            :class="['star', filledClass(n)]"
            @mouseover="onStarMouseOver(n)"
            @click="onStarMouseClick(n)">★</span>
  </span>
</template>

<script>
  export default {
    props: ['value', 'editable'],
    data: function () {
      return {
        overridenValue: null
      }
    },
    computed: {
      displayValue: function () {
        return this.overridenValue || this.value;
      },
      roundedDisplayValue: function () {
        return Math.round(this.displayValue);
      }
    },
    methods: {
      filledClass: function (n) {
        var isFilled = n <= this.roundedDisplayValue;
        return isFilled ? 'filled' : 'empty';
      },
      onStarMouseOver: function (n) {
        if (this.editable) {
          this.overridenValue = n;
        }
      },
      onStarMouseClick: function (n) {
        if (this.editable) {
          this.$emit('starclick', n);
        }
      },
      onMouseOut: function () {
        if (this.editable) {
          this.overridenValue = null;
        }
      }
    }
  }
</script>
