<template>
    <div class="thread" v-if="showThread()">
        <comment-view :comment="comment" v-for="comment in comments" @commentDeleteClick="onCommentDeleteClick"></comment-view>
        <div v-if="showReplyButton()">
            <a class="btn reply" @click="onReplyClick">Reply</a>
        </div>
        <div v-if="composing">
            <comment-form @save="onCommentFormSave" @cancel="onCommentFormCancel"/>
        </div>
    </div>
</template>

<script>

  import CommentView from 'comment-view';
  import CommentForm from 'comment-form';

  export default {
    props: ['comments', 'location'],
    components: { CommentForm, CommentView },
    data: function () {
      return {
        composing: false
      }
    },
    methods: {
      activate: function () {
        this.composing = true;
      },
      onCommentFormSave: function (comment) {
        var commentPath = window.location.pathname + '/comments';
        var payload = {
          comment: {
            text: comment.text,
            location: this.location
          }
        };
        $.post(commentPath, payload, (function (result) {
          this.composing = false;
          this.$emit('commentSaved');
        }).bind(this));
      },
      onCommentFormCancel: function () {
        this.composing = false;
      },
      onCommentDeleteClick: function (comment) {
        var commentPath = window.location.pathname + '/comments/' + comment.id;
        $.ajax({
          url: commentPath,
          type: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-HTTP-Method-Override": "DELETE"
          },
          success: (function () {
            this.$emit('commentSaved');
          }).bind(this)
        })
      },
      onReplyClick: function () {
        this.composing = true;
      },
      showReplyButton: function () {
        return !this.composing && this.comments.length > 0;
      },
      showThread: function () {
        return this.showReplyButton() || this.composing;
      },
    }
  }
</script>
