<template>
    <div>
        <div class="line-wrapper">
            <a @click="onNewCommentClick" class="comment-icon"></a>
            <div class="line">
                <div class="line-numbers">
                    <div class="line-number">
                        {{ line.line_number.left }}&nbsp;
                    </div>
                    <div class="line-number">
                        {{ line.line_number.right }}
                    </div>
                </div>
                <div :class="['line-content', line.edit_type]">{{ line.content }}</div>
            </div>
        </div>
        <thread-view ref="threadView" :comments="comments" :location="line.location" @commentSaved="onCommentSaved"/>
    </div>
</template>

<script>
  import ThreadView from 'thread-view';

  export default {
    props: ['line', 'comments'],
    components: {ThreadView},
    methods: {
      onNewCommentClick: function () {
        this.$refs.threadView.activate();
      },
      onCommentSaved: function () {
        this.$emit('commentSaved')
      }
    }
  }
</script>
