class ReviewApi {

  constructor(config) {
    this.diffEndpoint = config.diffEndpoint;
    this.commitsEndpoint = config.commitsEndpoint;
  }

  loadDiff(callback) {
    $.get(this.diffEndpoint, function (res) {
      callback(res);
    });
  }

  loadCommits(callback) {
    $.get(this.commitsEndpoint, function (res) {
      callback(res);
    });
  }
}

export default ReviewApi;
