/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

// import Vue from 'vue'
// import App from '../app.vue'
//
// document.addEventListener('DOMContentLoaded', () => {
//   const app = new Vue({
//     render: h => h(App)
//   }).$mount()
//   document.body.appendChild(app.$el)
//
//   console.log(app)
// })
//

// The above code uses Vue without the compiler, which means you cannot
// use Vue to target elements in your existing html templates. You would
// need to always use single file components.
// To be able to target elements in your existing html/erb templates,
// comment out the above code and uncomment the below
// Add <%= javascript_pack_tag 'hello_vue' %> to your layout
// Then add this markup to your html template:
//
// <div id='hello'>
//   {{message}}
//   <app></app>
// </div>


// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// document.addEventListener('DOMContentLoaded', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: {
//       message: "Can you say hello?"
//     },
//     components: { App }
//   })
// })
//
//
//
// If the project is using turbolinks, install 'vue-turbolinks':
//
// yarn add vue-turbolinks
//
// Then uncomment the code block below:
//
import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
import Watchers from '../watchers.vue'
import WatchersApi from '../WatchersApi';
import RatingsView from '../ratings-view';
import RatingsApi from '../RatingsApi';
import DiffView from '../diff-view';
import ReviewApi from '../ReviewApi';

Vue.use(TurbolinksAdapter);

document.addEventListener('turbolinks:load', () => {
  const el = document.getElementById('watchersList');
  if(el === null) {
    return;
  }
  const app = new Vue({
    el: el,
    template: '<Watchers :watcher-api="watcherApi"></Watchers>',
    data: () => {
      return {
        watcherApi: new WatchersApi(window.appConfig.watchers.endpoint),
      }
    },
    components: { Watchers }
  })
});

document.addEventListener('turbolinks:load', () => {
  const el = document.getElementById('ratingsApp');
  if(el === null) {
    return;
  }
  const app = new Vue({
    el: el,
    template: '<ratings-view :current-user="currentUser" :ratings-api="ratingsApi"></ratings-view>',
    data: () => {
      return {
        currentUser: window.appConfig.ratings.currentUser,
        ratingsApi: new RatingsApi(window.appConfig.ratings.endpoint)
      }
    },
    components: { RatingsView }
  })
});

document.addEventListener('turbolinks:load', () => {
  const el = document.getElementById('diffApp');
  if (el === null) {
    return;
  }

  const app = new Vue({
    el: el,
    template: '<diff-view :review-api="reviewApi" />',
    data: () => {
      return {
        reviewApi: new ReviewApi(window.appConfig.review)
      }
    },
    components: { DiffView }
  });
});

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
