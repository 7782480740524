<template>
    <div class="comment-form">
        <div class="field">
            <textarea v-model="text" @keyup.ctrl.enter="onSaveClick"/>
        </div>

        <div class="actions">
            <a class="btn" @click="onSaveClick">Save</a>
            <a class="btn" @click="onCancelClick">Cancel</a>
        </div>
    </div>
</template>

<script>
  export default {
    data: function () {
      return {
        text: ''
      }
    },
    methods: {
      onSaveClick: function () {
        this.$emit('save', {text: this.text});
      },
      onCancelClick: function () {
        this.$emit('cancel');
      }
    }
  }
</script>
