class WatchersApi {

  constructor(endpoint) {
    this.endpoint = endpoint;
  }

  load(callback) {
    $.get(this.endpoint, (function (response) {
      callback(response.all, response.watching);
    }));
  }

  add(watcher) {
    var payload = {
      watcher: {
        id: watcher.id
      }
    };
    $.post(this.endpoint, payload);
  }
  delete(watcher) {
    $.ajax({
      url: this.endpoint + '/' + watcher.id,
      type: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-HTTP-Method-Override": "DELETE"
      }
    })
  }
}

export default WatchersApi;
