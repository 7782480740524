<template>
    <div>
        <div class="infobox comments">
            <div class="heading">Comments ({{ comments.length }})</div>
            <div>
                <thread-view ref="standaloneThreadView" :comments="standaloneComments()" @commentSaved="onCommentSaved" />
            </div>
            <a v-if="showAddButton()" class="btn" @click="onStandaloneAddClick">Add</a>

            <div v-for="location in obsoleteLocations">
                <br />
                <p class="low-emphasis">The following comment thread originally appeared at a location that is no longer in the diff:</p>
                <thread-view :location="location" :comments="commentsForLocation(location)" @commentSaved="onCommentSaved" />
            </div>
        </div>



        <div class="tabs">
            <a @click="onDiffTabClick" :class="{ highlight: viewing =='Diff' }">Changes</a>
            <a @click="onCommitsTabClick" :class="{ highlight: viewing =='Commits' }">Commits ({{ commits.length }})</a>
        </div>

        <br />

        <div v-for="commit in commits" v-if="viewing == 'Commits'" class="commit">
            <div class="heading">
                <div class="commit-info">
                <div class="subject">{{ commit.subject }}</div>
                <div class="commit-hash">{{ commit.commit }}</div>
                </div>
                <div class="author-info">
                    {{ commit.author }}<br />
                    <span class="date">{{ sftime(commit.date) }} on {{ sfdate(commit.date) }}</span>
                </div>
            </div>
            <pre class="body" v-if="commit.body">{{ commit.body }}</pre>
        </div>

        <div v-if="viewing == 'Diff'">
            <div class="infobox" v-if="diff.files.length === 0">
                <div class="heading">No Changes</div>
                <p>
                    When the candidate has pushed some changes to the <em>master</em> branch, a diff of the changes
                    they have made will appear here.
                </p>
            </div>
            <div class="diff-file" v-for="file in diff.files" >
                <div class="diff-file-heading">{{ fileHeading(file) }}</div>
                <div class="diff-file-contents">
                    <div class="diff-file-hunks" v-for="hunk in file.hunks">
                        <div v-for="line in hunk.lines">
                            <line-view :line="line" :comments="commentsForLine(line)" @commentSaved="onCommentSaved"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import ThreadView from 'thread-view'
  import LineView from 'line-view'


  export default {
    props: ['reviewApi'],
    components: { ThreadView, LineView },
    data: function () {
      return {
        comments: [],
        commits: [],
        diff: { files: [] },
        viewing: 'Diff',
      }
    },
    computed: {
      obsoleteLocations: function() {
        const allLocations = this.comments.filter(c => c.location).map(c => c.location);
        const unusedLocations = new Set(allLocations);
        this.diff.files.forEach((file) => {
          file.hunks.forEach((hunk) => {
            hunk.lines.forEach((line) => {
              unusedLocations.delete(line.location);
            });
          });
        });
        return Array.from(unusedLocations);
      },
    },
    methods: {
      commentsForLine: function (line) {
        if (!this.comments) return [];
        return this.comments.filter(function (c) {
          return c.location === line.location
        });
      },
      commentsForLocation: function (location) {
        if (!this.comments) return [];
        return this.comments.filter(function (c) {
          return c.location === location;
        });
      },
      standaloneComments: function () {
        return this.comments.filter(function (c) {
          return !c.location
        });
      },
      sftime: function (dateStr) {
        var options = {
          hour: '2-digit',
          minute: '2-digit'
        };
        var d = new Date(dateStr);
        return d.toLocaleString('en-gb', options);
      },
      sfdate: function (dateStr) {
        var options = {
          year: '2-digit',
          month: '2-digit',
          day: '2-digit'
        };
        var d = new Date(dateStr);
        return d.toLocaleString('en-gb', options);
      },
      onStandaloneAddClick: function () {
        this.$refs.standaloneThreadView.activate();
      },
      onCommitsTabClick: function () {
        this.viewing = 'Commits'
      },
      onDiffTabClick: function () {
        this.viewing = 'Diff'
      },
      showAddButton: function () {
        var hasComments = this.standaloneComments().length;
        var isComposing = this.$refs.standaloneThreadView && this.$refs.standaloneThreadView.composing;
        return !hasComments && !isComposing;
      },
      onCommentSaved: function () {
        this.loadComments();
      },
      loadComments: function () {
        $.get(window.location.pathname + '/comments', (function (comments) {
          this.comments = comments;
        }).bind(this));
      },
      fileHeading: function (file) {
        if (file.a_path === file.b_path) return file.b_path;
        if (file.a_path === '/dev/null') return file.b_path + ' (created)';
        if (file.b_path === '/dev/null') return file.a_path + ' (deleted)';
        return file.a_path + ' -> ' + file.b_path + ' (renamed)';
      }
    },
    created: function () {
      this.loadComments();
      this.reviewApi.loadCommits((commits) => {
        this.commits = commits;
      });
      this.reviewApi.loadDiff((diff) => {
        this.diff = diff;
      });
    }
  }
</script>
