<template>
    <div class="ratings">
        <div class="infobox">
            <div class="heading">Average Rating</div>
            <div v-if="ratings.length > 0">
                <rating-value-view :value="average"/>
                <span class="rating-description">
           average of
          <em>{{ this.average.toFixed(1) }}</em>
          across
          <em>{{ ratings.length }} ratings</em>
        </span>
            </div>
            <div v-else>
                This assignment has no ratings yet.
            </div>
            <hr/>
            <br/>
            <div class="heading">Reviewer ratings</div>
            <div v-if="currentUserRating">
                <rating-value-view :value="currentUserRating.value" :editable="true" @starclick="onStarClick"/>
                <span class="rating-description"><em>{{ currentUserRating.user.name }}</em> (your rating)</span>
            </div>
            <div v-else>
                <rating-value-view :value="0" :editable="true" @starclick="onStarClick"/>
                <span class="rating-description">
          Click here to add this your rating to this assignment
        </span>
            </div>
            <div v-for="rating in otherUserRatings">
                <rating-value-view :value="rating.value"/>
                <span class="rating-description">{{ rating.user.name }}</span>
            </div>
        </div>
    </div>
</template>

<script>
  import RatingValueView from './rating-value-view';

  export default {
    components: { RatingValueView },
    props: ['currentUser', 'ratingsApi'],
    data: function() {
      return {
        ratings: []
      }
    },
    computed: {
      average: function () {
        var sum = this.ratings
          .map(function (rating) {
            return rating.value
          })
          .reduce(function (a, b) {
            return a + b
          }, 0);
        return sum / this.ratings.length
      },
      currentUserRating: function () {
        return this.ratings.find((function (rating) {
          return rating.user.id === this.currentUser.id
        }).bind(this));
      },
      otherUserRatings: function () {
        return this.ratings.filter((function (rating) {
          return rating.user.id !== this.currentUser.id
        }).bind(this));
      }
    },
    methods: {
      onStarClick: function (n) {
        if (this.currentUserRating) {
          this.currentUserRating.value = n;
        } else {
          this.ratings.push({
            value: n,
            user: this.currentUser
          });
        }
        this.ratingsApi.rate(n);
      }
    },
    created: function () {
      this.ratingsApi.load((function (ratings) {
        this.ratings = ratings;
      }).bind(this));
    },
  }
</script>
