<template>
    <div class="comment">
        <div class="top-bar">
            <div class="heading">
                {{ comment.author.name }}
                <span class="additional">at {{ sftime(comment.created_at) }} on {{ sfdate(comment.created_at) }}</span>
            </div>
            <div class="icons">
                <a @click="onDeleteClick">✗</a>
            </div>
        </div>
        <p>{{ comment.text }}</p>
    </div>
</template>

<script>
  export default {
    props: ['comment'],
    methods: {
      sftime: function (dateStr) {
        var options = {
          hour: '2-digit',
          minute: '2-digit'
        };
        var d = new Date(dateStr);
        return d.toLocaleString('en-gb', options);
      },
      sfdate: function (dateStr) {
        var options = {
          year: '2-digit',
          month: '2-digit',
          day: '2-digit'
        };
        var d = new Date(dateStr);
        return d.toLocaleString('en-gb', options);
      },
      onDeleteClick: function () {
        if (confirm('Are you sure you want to delete this comment?')) {
          this.$emit('commentDeleteClick', this.comment);
        }
      }
    }
  }
</script>
