class RatingsApi {

  constructor(endpoint) {
    this.endpoint = endpoint
  }

  load(callback) {
    $.get(this.endpoint, (function (response) {
      callback(response);
    }));
  }

  rate(value) {
    var payload = {
      rating: {
        value: value
      }
    };
    $.post(this.endpoint, payload);
  }
}

export default RatingsApi;
